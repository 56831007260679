import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import "../../static/optirec.css";
import rectangle from "./rectangle.svg";
import grip from "./grip.png";
import holder from "./holder.png";

const Bullets = () => {
  return (
    <Container>
      <Grid
        item
        container
        xs={12}
        sx={{ justifyContent: { xs: "center", md: "space-between" } }}
      >
        <Grid
          item
          container
          md={7}
          sm={10}
          xs={12}
          order={{ xs: 2, md: 1 }}
          className="rectangle"
        >
          <img src={rectangle} className="rectangle"></img>
          <Grid
            item
            container
            xs={5}
            className="images"
            flexDirection={"column"}
            alignItems={"center"}
            style={{
              top: "50%",
              transform: "translate(0px, -50%)",
              left: "0",
              padding: "20px",
            }}
            sx={{ fontSize: { xs: "20px", md: "30px" } }}
          >
            <img src={grip} style={{ maxWidth: "100%" }}></img>
            <Typography
              fontWeight={"bold"}
              textAlign="center"
              color={"white"}
              paddingTop={{ xs: 0 }}
              sx={{ fontSize: { xs: "18px", sm: "30px" } }}
            >
              Ocular grip
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={5}
            className="images"
            flexDirection={"column"}
            alignItems={"center"}
            style={{
              top: "50%",
              transform: "translate(0px, -50%)",
              right: "0",
              padding: "20px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              textAlign="center"
              color={"white"}
              paddingTop={{ xs: 0 }}
              sx={{ fontSize: { xs: "18px", sm: "27px" } }}
            >
              Universal holder
            </Typography>
            <img src={holder} style={{ maxWidth: "100%" }}></img>
          </Grid>
        </Grid>
        <Grid
          order={{ xs: 1, md: 2 }}
          item
          container
          md={4}
          xs={12}
          className="image-container"
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <h1 className="sticky_h1">Your Slit Lamp, but SMARTER.</h1>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Bullets;
